import {BikiniEvent, BikiniEventStatus, AlgoliaRecord} from '../types/bikini'
import {Programmation} from '../types/packweb'
import {Site} from '../types/site'
import {MusicEvent, WithContext, ItemAvailability, EventStatusType, EventAttendanceModeEnumeration} from 'schema-dts'

import React, {useState, useRef, useEffect} from 'react'
import {graphql, Link} from 'gatsby'
import algoliasearch from 'algoliasearch'
import styled, {css, ThemeProvider} from 'styled-components'

import Layout from '../layout'
import Banner from '../components/banner'
import Img from '../components/image'
import Video from '../components/video'
import Tags from '../components/tags'
import Event from '../components/event'
import Facebook from '../images/facebook'
import LinkIcon from '../images/link'
import Twitter from '../images/twitter'
import Loader from '../images/loader'
import Instagram from '../images/instagram'
import SoundCloud from '../images/soundcloud'
import YouTube from '../images/youtube'

import {getHeight, DESKTOP_HEIGHT} from '../layout/header'
import {dayMonthYear, timeFormat, upcoming, currentTimestamp, EVENT_DURATION, iso8601, timeLabel} from '../helpers/date'
import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {apiFetch, liveMerge, NetworkError} from '../helpers/api'
import {available, ellipsis, getSubtitle, getStartPrice} from '../helpers/text'
import algolia from '../helpers/algolia'
import {intersec} from '../helpers/array'
import {socialButton} from '../helpers/style'
import BookButton from '../components/bookbutton'

const NUM_RELATED = 6

const RELATED_BTF_TRIGGER = 200 // pixels below the fold

const BREAKPOINT = 1070 // pixels width

const SOCIAL_ICONS = {
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  youtube: YouTube,
  soundcloud: SoundCloud,
}

const LIVE_MAP = [
  {
    source: ['complet', 'presqueComplet', 'annule', 'reporte'],
    target: 'status',
    mapper: ({complet, presqueComplet, annule, reporte}: Programmation): BikiniEventStatus => {
      if (reporte) {
        return 'postponed'
      }
      if (annule) {
        return 'cancelled'
      }
      if (complet) {
        return 'full'
      }
      if (presqueComplet) {
        return 'almost'
      }
      return null
    },
  },
  {
    source: ['tarif', 'tarifs'],
    target: 'prices',
    mapper: ({tarif, tarifs}: Programmation): string[] => (tarifs[0] ? tarifs : [tarif]),
  },
  {
    source: ['gratuit'],
    target: 'free',
  },
  {
    source: ['date'],
    target: 'date',
  },
]
const LIVE_ATTRIBUTES = LIVE_MAP.reduce((prev: string[], curr) => prev.concat(curr.source), [])

const getSize = (avail: number, min: number, max: number) => Math.max(Math.min(min, avail), Math.min(max, avail / 2))

const getPosition = (avail: number, size: number) => Math.round(avail / 2 - size / 2)

const openPopup = (name: string, url: string, bestHeight?: number) => {
  const {availWidth, availHeight} = screen
  const width = getSize(availWidth, 600, 1200)
  const height = getSize(availHeight, bestHeight || 400, bestHeight || 800)
  const left = getPosition(availWidth, width)
  const top = getPosition(availHeight, height)
  window.open(url, name, `width=${width},height=${height},left=${left},top=${top}`)
}

const getStatus = (status: BikiniEventStatus): EventStatusType => {
  if (status === 'postponed') {
    return EventStatusType.EventPostponed
  }
  if (status === 'cancelled') {
    return EventStatusType.EventCancelled
  }
  return EventStatusType.EventScheduled
}

const getAvailability = (status: BikiniEventStatus) => {
  if (status === 'full') {
    return ItemAvailability.SoldOut
  }
  if (status === 'almost') {
    return ItemAvailability.LimitedAvailability
  }
  if (status === 'cancelled') {
    return ItemAvailability.Discontinued
  }
  return ItemAvailability.InStock
}

enum Position {
  top = 'top',
  sticky = 'sticky',
  bottom = 'bottom',
}

const POSITIONS = {
  [Position.top]: `position: absolute; top: 0; right: 0;`,
  [Position.sticky]: `position: fixed; top: ${DESKTOP_HEIGHT + 20}px; left: 50%; margin-left: 180px;`, // half + margin
  [Position.bottom]: `position: absolute; bottom: 0; right: 0;`,
}

const Container = styled.div`
  position: relative;
`
const Infos = styled.div<{position: Position}>`
  background: ${colors.white};
  line-height: 1.4rem;
  margin: 10px 0 0;
  position: relative;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 660px;
    margin: 20px 0 0;
  }
  @media (min-width: ${BREAKPOINT}px) {
    width: 320px;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    margin: 0;
    ${(props) => POSITIONS[props.position]};
  }
`
const InfoHeader = styled.div`
  padding: 18px 20px;
  border-bottom: 2px solid ${colors.background};
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.95rem;
  }
`
const InfoDate = styled.span`
  font-weight: 600;
  display: inline-block; /* for first-letter to work */
`
const InfoContent = styled.div`
  flex: 1;
`
const InfoTitle = styled.div`
  margin: 10px 20px 5px;
  font-weight: bold;
`
const InfoStyle = styled.div`
  margin: 0 20px 10px;
`
const Info = styled.div`
  margin: 0 20px;
`
const Prices = styled.div`
  white-space: pre-line;
  margin: 0 20px 10px;
`
const Action = styled.div`
  text-align: center;
  padding: 5px 0;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20px 0;
  }
`
const Book = styled(BookButton)<{available: boolean}>`
  background: ${(props) => (props.available ? colors.primary : colors.red)};
  color: ${(props) => (props.available ? colors.white : colors.primary)};
  display: inline-block;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px 0 5px;
  font-weight: 600;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.85rem;
    padding: 8px 18px;
    margin: 5px 0;
  }
`
const Label = styled.label`
  display: block;
  padding: 15px 20px;
`
const MailingForm = styled.form`
  display: flex;
  > * {
    height: 46px;
    padding: 0 20px;
  }
`
const Input = styled.input`
  flex: 2;
  border: none;
  background: ${colors.grey};
  color: ${colors.white};
  ::placeholder {
    color: ${colors.white};
  }
`
const FormButton = styled.button`
  flex: 1;
  background: ${colors.lightGrey};
  color: ${colors.primary};
  font-weight: bold;
  :disabled {
    color: ${colors.grey};
  }
`
const InfoFooter = styled.div<{border: boolean}>`
  font-weight: 600;
  padding: 15px 20px;
  ${(props) => (props.border ? `border-top: 2px solid ${colors.background}; margin-top: 10px;` : '')}
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  span {
    margin: 0 10px;
    white-space: nowrap;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    span {
      margin: 0;
      font-size: 0.95rem;
    }
    padding: 15px;
  }
`
const ClipboardFeedback = styled.div<{visible: boolean}>`
  background: ${colors.white};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 60px;
  ${(props) => (props.visible ? 'opacity: 1' : 'transition: opacity 0.5s; opacity: 0; pointer-events: none')};
`

const SocialButton = styled.button`
  ${socialButton()}
  margin: 0 10px 0 15px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
  }
`
const SocialLink = styled.a`
  ${socialButton()}
  margin: 0 35px 0 0;
`

const Article = styled.article`
  width: 660px;
  iframe {
    display: block;
    width: 100%;
    height: 370px;
    margin: 40px 0 0;
  }
  iframe.track {
    height: 170px;
  }
  iframe.user,
  iframe.playlist {
    height: 390px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin: 20px 0;
    iframe {
      height: 170px;
      margin: 10px 0 0;
    }
  }
`
const ImageContainer = styled.div`
  position: relative;
  width: 660px;
  height: 450px;
  background: ${colors.white};
  overflow: hidden;
  /* because no IE11 support for object-fit on child image: */
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: 65vw; /* = 100 x 450 / 660 minus the approx padding */
  }
`
const Image = styled(Img)`
  width: 100%;
`

const labelCss = css`
  position: absolute;
  right: 22px;
  font-weight: 600;
  font-size: 0.85rem;
`
const Place = styled.div`
  ${labelCss}
  background: ${colors.tags.other};
  bottom: 20px;
  padding: 5px 14px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    right: 15px;
    bottom: 15px;
  }
`
const AdultsOnly = styled.span`
  ${labelCss}
  background: ${colors.primary};
  color: ${colors.red};
  border-radius: 20px;
  top: 20px;
  padding: 9px 20px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    right: 15px;
    top: 15px;
    padding: 7px 17px;
  }
`

const Content = styled.div`
  line-height: 1.5rem;
  h1 {
    line-height: 3rem;
    margin-top: 50px;
    font-size: 2.5rem;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.9rem;
    h1 {
      font-size: 1.55rem;
      line-height: 2rem;
    }
  }
`
const Title = styled.h1`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`
const Social = styled.div`
  margin: 35px 0;
`

const Opening = styled.h2`
  margin: 55px 0 -45px;
  color: ${colors.grey};
`
const Address = styled.div`
  white-space: pre-line;
`
const GoogleMap = styled.div`
  iframe {
    width: 100%;
    height: 470px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    iframe {
      height: 220px;
    }
  }
`
const Separator = styled.div`
  width: 660px;
  height: 12px;
  background: ${colors.primary};
  display: block;
  margin: 55px 0 55px 340px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100% - 50px);
    height: 5px;
    margin: 25px 0 25px 50px;
  }
`
const Related = styled.div``
const RelatedTitle = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 1.55rem;
    padding: 0 10px;
  }
`
const Events = styled.div`
  ${(props) => (props.theme.squares ? 'display: flex; flex-wrap: wrap; margin: 40px -10px;' : 'margin: 20px 0;')}
`

const Clipboard = styled.textarea`
  position: absolute;
  left: -10000px;
`

interface Props {
  data: {
    site: Site
    bikiniEvent: BikiniEvent
  }
}

const EventTemplate: React.FC<Props> = ({data: {site, bikiniEvent}}) => {
  const [liveItem, setItem] = useState<Programmation>()
  const [position, setPosition] = useState<Position>(Position.top)
  const [showFeedback, setFeedback] = useState(false)
  const [related, setRelated] = useState<AlgoliaRecord[]>()
  const [squares, setSquares] = useState(false) // for theme povider
  const [pageUrl, setUrl] = useState<string>()
  const [submitting, setSubmitting] = useState(false)
  const containerRef = useRef<HTMLDivElement>()
  const boxRef = useRef<HTMLDivElement>()
  const boxBottom = useRef<number>(100000)
  const timerRef = useRef<number>()
  const textAreaRef = useRef<HTMLTextAreaElement>()
  const moreRef = useRef(true)

  const {
    siteMetadata: {twitter, hostname},
  } = site

  const event: BikiniEvent = liveMerge(bikiniEvent, liveItem, LIVE_MAP)
  const {
    id,
    path,
    title,
    date,
    end,
    _tags,
    categories,
    place,
    prices,
    free,
    unveilDate,
    image,
    imagePosition,
    content,
    socialLinks,
    videos,
    ticketUrl,
    openingArtist,
    openingText,
    openingLinks,
    openingVideos,
    description,
    status,
    mailingUrl,
    adultsOnly,
  } = event

  useEffect(() => {
    if (date < currentTimestamp() - EVENT_DURATION) {
      // old event: no need for live data
      return
    }

    const controller = typeof AbortController !== 'undefined' ? new AbortController() : null

    apiFetch(
      [
        {
          name: 'programmation',
          filters: [{attribute: 'id', operator: '=', value: id.split('-')[1]}],
          attributes: LIVE_ATTRIBUTES,
        },
      ],
      controller && controller.signal
    )
      .catch((err) => {
        // failed to fetch or AbortError
        throw new NetworkError(err.message)
      })
      .then(({programmation: {data}}) => {
        if (data.length !== 1) {
          throw new Error(`Live API check returned ${data.length} results for ${id}`)
        }
        setItem(data[0])
      })
      .catch((err) => {
        if (!(err instanceof NetworkError)) {
          throw err // let it bubble to Bugsnag
        }
      })

    if (controller) {
      return () => {
        controller.abort()
      }
    }
  }, [])

  const handleFacebook = () => {
    openPopup('fb', `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(location.href)}`)
  }

  const handleTwitter = () => {
    const atBikini = _tags.includes('bikini')
    const where = atBikini ? `@${twitter}` : `@ ${place.name}`
    const tweet = [`${upcoming(date, end)} ${where} :`, title + ' !']
    if (!atBikini) {
      tweet.push(`via @${twitter}`)
    }

    const text = tweet.join('\n') + '\n'

    openPopup(
      'twitter',
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(location.href)}`,
      275
    )
  }

  // hack for copying page link using document.execCommand
  // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard#Writing_to_the_clipboard
  const handleLink = () => {
    textAreaRef.current.select()
    document.execCommand('copy')
    setFeedback(true)
    timerRef.current = setTimeout(() => setFeedback(false), 2000)
  }

  const handleRelated = (error: Error, response: algoliasearch.Response) => {
    if (error) {
      throw error
    }
    const hits = response.hits.filter((record: AlgoliaRecord) => record.objectID !== id)

    // start by showing the concerts that have more categories in common
    // then sort by date otherwise:
    hits.sort((left: AlgoliaRecord, right: AlgoliaRecord) => {
      const catDiff = intersec(right.categories, categories).length - intersec(left.categories, categories).length
      return catDiff * 10 + (left.date > right.date ? 1 : -1)
    })

    setRelated(hits.slice(0, NUM_RELATED))
  }

  const fetchRelated = () => {
    let filters = `date>${currentTimestamp()} AND status:-full AND status:-cancelled`
    if (categories.length) {
      const facetFilters = categories.map((cat) => `categories:${cat}`)
      filters += ` AND (${facetFilters.join(' OR ')})`
    }
    const params = {
      filters,
      hitsPerPage: NUM_RELATED * 2, // request more to sort on the front-end, but no too many to stay in the near future
    }
    algolia.search(params, handleRelated)
  }

  const handleMailing = () => {
    setSubmitting(true)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) {
        return
      }
      const {top, bottom} = containerRef.current.getBoundingClientRect()
      if (bottom - window.innerHeight < RELATED_BTF_TRIGGER && moreRef.current) {
        fetchRelated()
        moreRef.current = false // trigger only once
      }

      if (window.innerWidth < BREAKPOINT || !boxRef.current) {
        // no sticky infos on mobile
        return
      }
      if (top > getHeight() + 20) {
        setPosition(Position.top)
        boxBottom.current = Math.min(boxBottom.current, boxRef.current.getBoundingClientRect().bottom) // trick to improve accuracy
      } else if (bottom < boxBottom.current) {
        setPosition(Position.bottom)
      } else {
        setPosition(Position.sticky)
      }
    }

    document.addEventListener('scroll', handleScroll, {passive: true})
    handleScroll()
    setSquares(window.innerWidth > MOBILE_BREAKPOINT)
    setUrl(location.href)
    return () => {
      document.removeEventListener('scroll', handleScroll)
      clearTimeout(timerRef.current)
    }
  }, [])

  const titleParts = title.split('@')[0].split(':')
  const performers = titleParts[titleParts.length - 1].split('+') // TODO: handle this in backoffice

  const structured: WithContext<MusicEvent> = {
    '@context': 'https://schema.org',
    '@type': 'MusicEvent',
    name: title,
    description: ellipsis(description, 255),
    startDate: iso8601(date),
    endDate: iso8601(end || date + EVENT_DURATION),
    location: {
      '@type': 'Place',
      name: place.name,
      address: place.address.replace('\n', ', '),
    },
    performer: performers.map((performerStr) => ({
      '@type': 'PerformingGroup',
      name: performerStr.replace(/\[[^\]]+\]/, '').trim(),
    })),
    eventStatus: getStatus(status),
    eventAttendanceMode: EventAttendanceModeEnumeration.OfflineEventAttendanceMode,
  }
  if (image) {
    structured.image = `https://${hostname}${image.publicURL}`
  }

  structured.offers = prices
    .map((line) => {
      const match = line.match(/\d+([.,]\d{1,2}|)/)
      return match && Number(match[0].replace(',', '.'))
    })
    .filter((price) => price)
    .map((price) => ({
      '@type': 'Offer',
      url: ticketUrl,
      price,
      priceCurrency: 'EUR',
      availability: getAvailability(status),
      validFrom: iso8601(unveilDate),
    }))

  const formattedDate = dayMonthYear(date, end, true)

  const customTags = _tags.filter((tag) => !colors.tags[tag])
  const timeStr = timeLabel(_tags) + timeFormat(date)

  return (
    <Layout
      title={`${formattedDate} : ${title}`}
      image={image}
      description={`${formattedDate} @ ${place.name} : ${title}. ${event.style}. ${timeStr}. ${getStartPrice(event)}.`}
      url={`https://${hostname}${path}`}
      noindex={unveilDate > currentTimestamp() + 3600} // prevent from putting hidden concerts into Google
    >
      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structured)}} />
      <Banner categories={categories} />
      <Container ref={containerRef}>
        <ImageContainer style={{alignItems: imagePosition}}>
          <Image src={image} />
          {_tags.includes('other') && <Place>{place.name}</Place>}
          {adultsOnly && <AdultsOnly>+18 ans</AdultsOnly>}
        </ImageContainer>
        <Infos position={position} ref={boxRef}>
          <InfoHeader>
            <Tags tags={_tags} />
            <InfoDate>{formattedDate}</InfoDate>
          </InfoHeader>
          <InfoContent>
            <InfoTitle>{title}</InfoTitle>
            <InfoStyle>{getSubtitle(event)}</InfoStyle>
            <Prices>{prices.join('\n')}</Prices>
            <Info>{timeStr}</Info>
            {customTags.length > 0 && (
              <Info>
                <br />
                {customTags.map((tag) => (
                  <Link key={tag} to={`/tag/${tag}`}>{`#${tag}`}</Link>
                ))}
              </Info>
            )}
          </InfoContent>
          {(ticketUrl || free) && (
            <Action>
              <Book event={event} available={available(event)} />
            </Action>
          )}
          {mailingUrl && (
            <>
              <Label>Créer une alerte en cas de nouvelles places disponibles :</Label>
              <MailingForm method="post" action={mailingUrl} onSubmit={handleMailing}>
                <Input type="email" name="EMAIL" placeholder="Adresse e-mail" aria-label="E-mail" />
                <FormButton type="submit" disabled={submitting}>
                  Créer
                </FormButton>
              </MailingForm>
            </>
          )}
          <InfoFooter border={!mailingUrl}>
            <span>Partager sur</span>
            <SocialButton title="Facebook" onClick={handleFacebook}>
              <Facebook />
            </SocialButton>
            <SocialButton title="Twitter" onClick={handleTwitter}>
              <Twitter />
            </SocialButton>
            <SocialButton title="Copier le lien" onClick={handleLink}>
              <LinkIcon />
            </SocialButton>
            <ClipboardFeedback visible={showFeedback}>Lien copié !</ClipboardFeedback>
          </InfoFooter>
        </Infos>
        <Article>
          <Content>
            <Title>{title}</Title>
            {content && <div dangerouslySetInnerHTML={{__html: content}} />}
            <Social>
              {socialLinks.map(({provider, url}, index) => {
                const Icon = SOCIAL_ICONS[provider]
                return (
                  <SocialLink href={url} key={index} target="_blank" rel="noopener" title={url}>
                    <Icon />
                  </SocialLink>
                )
              })}
            </Social>
            {videos.map((video, index) => (
              <Video key={index} {...video} />
            ))}
            {(openingArtist || openingText) && (
              <>
                <Opening>1ère partie</Opening>
                <h1>{openingArtist}</h1>
                {openingText && <div dangerouslySetInnerHTML={{__html: openingText}} />}
                <Social>
                  {openingLinks.map(({provider, url}, index) => {
                    const Icon = SOCIAL_ICONS[provider]
                    return (
                      <SocialLink href={url} key={index} target="_blank" rel="noopener" title={url}>
                        <Icon />
                      </SocialLink>
                    )
                  })}
                </Social>
                {openingVideos.map((video, index) => (
                  <Video key={index} {...video} />
                ))}
              </>
            )}
            <h1>Où ?</h1>
            <Address>
              <strong>{place.name}</strong>
              <br />
              {place.address}
            </Address>
            <GoogleMap dangerouslySetInnerHTML={{__html: place.map}} />
          </Content>
        </Article>
      </Container>
      <Separator />
      <Related>
        {!related || related.length ? <RelatedTitle>Ces événements devraient vous plaire</RelatedTitle> : null}
        <ThemeProvider theme={{squares}}>
          <Events>
            {related ? (
              related.map((record: AlgoliaRecord) => <Event key={record.objectID} event={record} />)
            ) : (
              <Loader />
            )}
          </Events>
        </ThemeProvider>
      </Related>
      {pageUrl && <Clipboard ref={textAreaRef} value={pageUrl} readOnly aria-hidden />}
    </Layout>
  )
}

export default EventTemplate

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        twitter
        hostname
      }
    }
    bikiniEvent(id: {eq: $id}) {
      id
      path
      date
      end
      unveilDate
      title
      style
      categories
      place {
        name
        address
        map
      }
      _tags
      ticketUrl
      status
      password
      passwordLimitDate
      passwordPrompt
      startPrice
      prices
      free
      content
      socialLinks {
        provider
        url
      }
      adultsOnly
      image {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
            presentationWidth # for og:image:width
            presentationHeight # for og:image:height
          }
        }
        publicURL # for structured data
      }
      imagePosition
      videos {
        provider
        id
        type
      }
      openingArtist
      openingText
      openingLinks {
        provider
        url
      }
      openingVideos {
        provider
        id
        type
      }
      description
      mailingUrl
    }
  }
`
