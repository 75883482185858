import {ApiItemRequest} from '../types/packweb'

import HttpError from 'standard-http-error'

export const apiFetch = async (items: ApiItemRequest[], signal: AbortSignal) => {
  const response = await fetch(process.env.GATSBY_API + '/api', {
    headers: {
      'Content-Type': 'application/json',
      'X-api-token': process.env.GATSBY_API_TOKEN,
    },
    method: 'POST',
    body: JSON.stringify(items),
    signal,
  })
  if (response.status >= 400) {
    throw new HttpError(response.status, response.statusText)
  }
  return response.json()
}

export class NetworkError extends Error {
  name: 'NetworkError'
}

// TODO: arguments typing
export const liveMerge = (current, live, map) => {
  if (live) {
    map.forEach(({source, target, mapper}) => {
      if (source.every((attr) => live[attr] != null)) {
        current[target] = mapper ? mapper(live) : live[source[0]]
      }
    })
  }
  return current
}
